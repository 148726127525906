<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-8 offset-md-2" v-if="Object.keys(room).length !== 0">
          <h5><a @click="$router.go(-1)"><i class="fa fa-arrow-left" aria-hidden="true"></i></a> Meeting room booking for {{  room.name }}</h5>
          <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
            <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
          </div>
          <div class="card mb-3">
            <div class="card-body">
              <div class="form-row">
                <div class="col-md-6 col-sm-6 col-lg-6">
                  <label for="booking_date" class="col-form-label col-form-label-sm">Booking Date <span class="custom-required">*</span></label>
                  <datepicker v-model="search_data.booking_date" required id="booking_date" name="booking_date" :format="customBookingDate" :disabled-dates="disabledDates" placeholder="Booking Date" ></datepicker>
                </div>
              </div>
              <div  class="form-row mt-2">
                <div class="col-md-6 col-sm-6 col-lg-6">
                  <a-button type="primary" class="text-center mr-2" :loading="searchBtnLoader"  @click.prevent="search"><i class="fa fa-search mr-1" aria-hidden="true"></i> Check Available Date & Time</a-button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showBookDiv">
            <div v-if="Object.keys(available_room).length !== 0">
              <div class="card mb-3">
                <div class="card-body">
<!--                  <div class="col-md-8 offset-md-3">-->
<!--                    <div class="row">-->
<!--                      <div class="col-md-3">-->
<!--                        <div class="card">-->
<!--                          <div class="card-body text-center custom-inc-card custom-inc-card-booked">-->
<!--                            BOOKED-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-md-3">-->
<!--                        <div class="card">-->
<!--                          <div class="card-body text-center custom-inc-card custom-inc-card-available">-->
<!--                            AVAILABLE-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-md-3">-->
<!--                        <div class="card">-->
<!--                          <div class="card-body text-center custom-inc-card custom-inc-card-selected">-->
<!--                            SELECTED-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <hr style="margin-top: 0px !important;">-->
                  <h5 class="mb-3">Please Select Your Meeting Time:</h5>
                  <div class="row">
                    <div class="col-md-3" v-for="(time_slot, i) in available_room.time_slots" :key="i">
                      <div class="card">
                      <span v-if="time_slot.is_booked">
                        <div class="card-body text-center custom-inc-card-booked custom-book-card">
                          {{ time_slot.time_slot }}
                        </div>
                      </span>
                        <span v-if="!time_slot.is_booked">
                        <div class="card-body text-center custom-book-card" :class="!time_slot.bookedClass ? 'custom-inc-card-available' : 'custom-inc-card-selected' " :id="'book_' + time_slot.id" @click="bookRoom(time_slot.id, i)" style="cursor: pointer;">
                          {{ time_slot.time_slot }}
                        </div>
                      </span>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <h5>Please Share Below Details</h5>
                  <div class="row">
                    <div class="col-md-12 col-sm-12 col-lg-12">
                      <label for="name" class="col-form-label col-form-label-sm">Purpose of Your Meeting <span class="custom-required">*</span></label>
                      <input type="text" v-model="room_booking.meeting_purpose" v-validate="'required|max:255|min:4'" id="meeting_purpose" name="meeting_purpose" class="form-control form-control-sm" placeholder="Enter purpose of your meeting"/>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6">
                      <label for="name" class="col-form-label col-form-label-sm">Name <span class="custom-required">*</span></label>
                      <input type="text" v-model="room_booking.name" v-validate="'required|max:100|min:4'" id="name" name="name" class="form-control form-control-sm" placeholder="Enter name"/>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6">
                      <label for="department_id" class="col-form-label col-form-label-sm">Department <span class="custom-required">*</span></label>
                      <select v-model="room_booking.department_id" v-validate="'required'" id="department_id" name="department_id" class="form-control form-control-sm">
                        <option selected  :value="''">Select a department</option>
                        <option v-for="(department, index) in departments" :key="index" :value="department.id">{{ department.department_name }}</option>
                      </select>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6">
                      <label for="job_id" class="col-form-label col-form-label-sm">Job ID <span class="custom-required">*</span></label>
                      <input type="text" v-model="room_booking.job_id" v-validate="'required|max:30|min:4'" id="job_id" name="job_id" class="form-control form-control-sm" placeholder="Enter job ID"/>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6">
                      <label for="phone_no" class="col-form-label col-form-label-sm">Phone No. <span class="custom-required">*</span></label>
                      <input type="text" v-model="room_booking.phone_no" v-validate="{ required: true, regex: /^(8801[13456789]{1}[0-9]{8}|01[13456789]{1}[0-9]{8})$/}" id="phone_no" name="phone_no" class="form-control form-control-sm" placeholder="Enter phone no, ex: 017, 018..."/>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6">
                      <label for="email" class="col-form-label col-form-label-sm">Email <span class="custom-required">*</span></label>
                      <input type="text" v-model="room_booking.email" v-validate="'required|email'" id="email" name="email" class="form-control form-control-sm" placeholder="Enter email"/>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6">
                      <label for="no_if_person" class="col-form-label col-form-label-sm">No. of Participants <span class="custom-required">*</span></label>
                      <input type="text" v-model="room_booking.no_if_person" v-validate="{ required: true, max: 1, max: 3, regex: /^[1-9][0-9]*$/  }" id="no_if_person" name="no_if_person" class="form-control form-control-sm" placeholder="Enter no of person meeting"/>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6">
                      <label for="managers_email" class="col-form-label col-form-label-sm">Your Manager`s Email <span class="custom-required">*</span></label>
                      <input type="text" v-model="room_booking.managers_email" v-validate="'required|email'" id="managers_email" name="managers_email" class="form-control form-control-sm" placeholder="Enter your managers email"/>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6">
                      <label for="additional_facilities" class="col-form-label col-form-label-sm">Additional Requirement </label>
                      <v-select v-model="room_booking.additional_facilities" multiple class="border-c-class" name="additional_facilities" id="additional_facilities" label="name" :options="additional_facilities" placeholder="Select additional requirements"></v-select>
                    </div>
                    <div class="col-md-12 col-sm-12 col-lg-12">
                      <label for="other_requirements" class="col-form-label col-form-label-sm">Other Requirements</label>
                      <textarea v-model="room_booking.other_requirements" v-validate="'max:200'" id="other_requirements" name="other_requirements" class="form-control form-control-sm" placeholder="Enter other requirement" ></textarea>
                    </div>
                  </div>
                  <hr>
                  <div v-if="room.refreshment_required">
                    <h5>If Refreshment is Required <a href="javascript: void(0);" @click="restaurantDiv()">Click Here</a></h5>
                    <div v-show="restaurant_div">
                      <div class="row mb-3">
                        <div class="col-md-6 col-sm-6 col-lg-6">
                          <label for="restaurant_name" class="col-form-label col-form-label-sm">Any Preferred Restaurant </label>
                          <input type="text" v-model="room_booking.restaurant_name" v-validate="{ required: room_booking_entertainments.some(e => (e.food_item_name !== '' && e.food_item_name != null)) ? true : false, max: 100, min: 2 }" id="restaurant_name" name="restaurant_name" class="form-control form-control-sm" placeholder="Enter restaurant name"/>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 mb-2">
                          <button class="btn btn-sm btn-primary pull-right" @click="addItemRow">
                            <i class="fa fa-plus mr-1"></i>Add Food Items
                          </button>
                        </div>
                      </div>
                      <div class="table-responsive-lg">
                        <table class="table table-sm table-bordered text-center">
                          <thead>
                          <tr>
                            <th>Food Item</th>
                            <th>Unit</th>
                            <th>Qty</th>
                            <th>Unit Price</th>
                            <th>Total Price</th>
                            <th>Remarks</th>
                            <th>Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for='(room_booking_entertainment, index) in room_booking_entertainments' :key="index">
                            <td>
                              <input type="text" v-model="room_booking_entertainment.food_item_name"   v-validate="{ required: room_booking.restaurant_name && (room_booking_entertainment.qty || room_booking_entertainment.unit || room_booking_entertainment.unit_price) ? true : false, max: 100, min: 1 }" :name="'food_item_name_' + index" :id="'food_item_name_' + index" class="form-control-plaintext form-control-sm" :class="errors.has('food_item_name_'+index) ? 'custom-error-class' : ''" style="text-align: center;" placeholder="Enter food item name">
                            </td>
                            <td>
                              <input type="text" v-model="room_booking_entertainment.unit"  v-validate="{ required: room_booking_entertainment.food_item_name ? true : false, max: 50, min: 1 }" :name="'unit_' + index" :id="'unit_' + index"  class="form-control-plaintext form-control-sm" :class="errors.has('unit_'+index) ? 'custom-error-class' : ''" style="text-align: center;" placeholder="Enter item unit">
                            </td>
                            <td>
                              <input type="text" v-model="room_booking_entertainment.qty" v-validate="{  max: 6, min: 1, regex: /^[1-9][0-9]*$/, required: room_booking_entertainment.food_item_name ? true : false }"  :name="'qty_'+index" :id="'qty_' + index" class="form-control-plaintext form-control-sm" :class="errors.has('qty_'+index) ? 'custom-error-class' : ''"  style="text-align: center;" placeholder="Enter item qty">
                            </td>
                            <td>
                              <input type="text" v-model="room_booking_entertainment.unit_price" v-validate="{ required: room_booking_entertainment.food_item_name ? true : false, max: 6, regex: /^[1-9][0-9]*$/ }"  :name="'unit_price_' + index" :id="'unit_price_' + index" class="form-control-plaintext form-control-sm" :class="errors.has('unit_price_'+index) ? 'custom-error-class' : ''" style="text-align: center;" placeholder="Enter item unit price">
                            </td>
                            <td>
                              <input type="text" v-model="unitTotal[index]" v-validate="{ required: room_booking_entertainment.food_item_name ? true : false,max: 6, regex: /^[1-9][0-9]*$/ }" :name="'total_price_' + index" readonly disabled class="form-control-plaintext form-control-sm" :class="errors.has('total_price_'+index) ? 'custom-error-class' : ''" style="text-align: center;" :id="'total_price_'+room_booking_entertainment.id">
                            </td>
                            <td>
                              <input type="text" v-model="room_booking_entertainment.remarks"  name="remarks" id="remarks" class="form-control-plaintext form-control-sm" placeholder="Enter remarks" style="text-align: center;">
                            </td>
                            <td>
                              <button class="btn btn-sm btn-danger" @click="deleteItemRow(index)" >
                                <i class="fa fa-remove mr-1"></i>
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <h5 class="text-center text-secondary" v-if="room_booking_entertainments.length === 0">
                        No food items added yet
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <a-button class="btn btn-success waves-effect waves-light mr-2" :loading="submitLoading" @click.prevent="save"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Submit</a-button>
            </div>
            <div v-else>
              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="card">
                        <div class="card-body">
                          {{ available_message }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <a-skeleton active :loading="searchLoading"></a-skeleton>
          </div>
        </div>
        <div class="col-md-8 offset-md-2" v-else>
          <h5 class="text-center text-secondary" v-if="message">
            <div class="card">
              <div class="card-body">
                <a @click="$router.go(-1)"><i class="fa fa-arrow-left" aria-hidden="true"></i></a> {{ message }}
              </div>
            </div>
          </h5>
        </div>
        <div class="col-md-8 offset-md-2">
          <a-skeleton active :loading="loading"></a-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: { Datepicker },
  data() {
    return {
      departments: [],
      additional_facilities: [],
      room: {},
      room_booking: {
        meeting_purpose: '',
        name: '',
        department_id: '',
        job_id: '',
        phone_no: '',
        email: '',
        managers_email: '',
        no_if_person: '',
        additional_facilities: [],
        other_requirements: '',
        restaurant_name: '',
      },
      available_room: {},
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      loader: false,
      loading: false,
      searchLoading: false,
      roomFoundFlag: false,
      searchBtnLoader: false,
      submitLoading: false,
      showBookDiv: false,
      validation_errors: {},
      show: false,
      message: '',
      index: -1,
      available_message: '',
      disabledDates: {
        to: moment().subtract(1, 'days').toDate(),
      },
      search_data: {
        booking_date: '',
      },
      time_slots: [],
      room_booking_entertainments: [],
      restaurant_div: false,
    }
  },
  mounted() {
    this.getCodes()
    this.getRooms()
  },
  computed: {
    unitTotal() {
      return this.room_booking_entertainments.map((roomBookingEntertainment) => {
        return (parseFloat(roomBookingEntertainment.qty || 0) * parseFloat(roomBookingEntertainment.unit_price || 0) || 0)
      })
    },
  },
  methods: {
    customBookingDate(date) {
      this.search_data.booking_date = moment(date).format('YYYY-MM-DD')
      return moment(date).format('YYYY-MM-DD')
    },
    getCodes() {
      apiClient.get('api/fetch/codes')
        .then(response => {
          this.departments = response.data.departments
          this.additional_facilities = response.data.additional_facilities
        })
        .catch(error => {
          console.log(error)
        })
    },
    getRooms() {
      this.loading = true
      const roomId = this.$route.params.room_id
      apiClient.get('api/fetch/room/' + roomId)
        .then(response => {
          this.loading = false
          this.room = response.data.room
          this.message = response.data.message
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.resetForm()
      this.showBookDiv = false
      this.searchLoading = true
      this.searchBtnLoader = true
      const roomId = this.$route.params.room_id
      this.search_data.room_id = roomId
      apiClient.post('api/fetch/room/available', this.search_data)
        .then(response => {
          this.showBookDiv = true
          this.searchBtnLoader = false
          this.searchLoading = false
          this.available_room = response.data.available_room
          this.available_message = response.data.available_message
        })
        .catch(error => {
          this.showBookDiv = false
          this.searchBtnLoader = false
          this.searchLoading = false
          this.validation_errors = error.response.data.errors
          this.show = true
          this.hide()
        })
    },
    bookRoom(timeSlotId, index) {
      if (this.time_slots.includes(timeSlotId)) {
        this.time_slots.splice(this.time_slots.indexOf(timeSlotId), 1)
        this.available_room.time_slots[index].bookedClass = false
      } else {
        this.time_slots.push(timeSlotId)
        this.available_room.time_slots[index].bookedClass = true
      }
    },
    addItemRow: function () {
      this.room_booking_entertainments.push({
        food_item_name: '',
        qty: '',
        unit: '',
        unit_price: '',
        total_price: '',
        remarks: '',
      })
    },
    deleteItemRow(index) {
      this.room_booking_entertainments.splice(index, 1)
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          // check time slot empty
          if (this.time_slots.length === 0) {
            this.$notification.error({
              message: 'Please select atleast one time slot',
            })
            return
          }
          // check div false, then empty restaurant name and items
          if (this.restaurant_div === false) {
            this.room_booking.restaurant_name = ''
            this.room_booking_entertainments = []
          }
          // remove empty food items
          const roomBookingEntertainments = this.room_booking_entertainments.filter(element => {
            if (element.food_item_name !== '' && element.food_item_name != null) {
              return true
            }
            return false
          })
          // check food item is empty
          if (this.room_booking.restaurant_name !== '') {
            if (this.room_booking_entertainments.length === 0) {
              this.$notification.error({
                message: 'Please add atleast one food item',
              })
              return
            }
          }
          this.submitLoading = true
          this.room_booking.room_booking_entertainments = JSON.stringify(roomBookingEntertainments)
          this.room_booking.time_slots = JSON.stringify(this.time_slots)
          this.room_booking.booking_date = this.search_data.booking_date
          this.room_booking.room_id = this.$route.params.room_id
          apiClient.post('/api/room/b/cs', this.room_booking)
            .then(response => {
              this.submitLoading = false
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
                this.$router.push({ name: 'roomBooking' })
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.submitLoading = false
              this.validation_errors = error.response.data.errors
              this.show = true
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
    resetForm() {
      this.room_booking = {}
      this.time_slots = []
      this.room_booking_entertainments = []
    },
    restaurantDiv() {
      this.restaurant_div = !this.restaurant_div
    },
  },
}
</script>

<style scoped>
.custom-inc-card {
  padding: 5px !important;
}
.custom-book-card {
  padding: 10px !important;
}
.custom-inc-card-booked {
  background-color: #ff00009e !important;
  color: #000000 !important;
}
.custom-inc-card-available {
  background-color: white !important;
}
.custom-inc-card-selected {
  background-color: #008000c2 !important;
  color: #000000 !important;
}
.border-c-class {
  border-color: #e4e9f0 !important;
}
.vs--searchable .vs__dropdown-toggle {
  cursor: text;
  border-color: #e4e9f0 !important;
}
.table{
  border: none !important;
  border-spacing: 0 !important;
  border-radius: 0.25rem !important;
}
.custom-error-class{
  border-bottom: 1px solid #dc3545 !important;
}
</style>
